<template>
  <div>
    <Widget
      ref="widget"
      :height="height"
      :model="model"
      :defaults="{ paramName: 'dashBoard.actWorkClient', filters: {} }"
      :api="api"
      @click="onClick($event)"
      :isLoading="loading"
      @loading="loading = $event"
    >
      <template v-slot:title> Акты выполненных работ </template>
    </Widget>
    <s-quickOpenDoc ref="quickOpenDoc" @onClose="itemShowClose" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </div>
</template>

<script>
import { popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [popupMenu, genModel],
  components: {
    Widget: () => import("./widgets/apiTable"),
  },
  props: {
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      idShow: 0,
      contentHeight: 10,
      hideViewed: true,
      loading: false,
      data: [],
      showConfig: false,
      timeID: null,
      array_object: [],
      m: this.$store.getters["config/get"].models.documentActWorkClient,
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    api() {
      return this.m.api;
    },
    model() {
      //return this.getModelList(this.m);
      return [
        { name: "id", title: "id", type: "number", sortable: true, width: 30, hidden: true },
        { name: "code_doc", title: "Номер", type: "string", sortable: true, width: 50 },
        { name: "object_id", title: "Объект", type: "select", dir: "object", sortable: true, width: 150, style: "white-space: normal;" },
        { name: "date_doc", title: "Дата", type: "datetime", sortable: true, width: 120 },
        { name: "client_id", title: "Клиент", type: "select", dir: "client", filter: { type: "select", name: "client_id", dir: "client" }, sortable: true, width: 130 },
        {
          name: "investor_id",
          title: "Инвестор",
          type: "select",
          dir: "investor",
          filter: { type: "select", name: "investor_id", dir: "investor" },
          sortable: true,
          hidden: true,
          width: 130,
        },
        { name: "createdby_id", title: "Автор", type: "select", dir: "user", filter: { type: "select", name: "createdby_id", dir: "user" }, sortable: true, width: 150 },
        { name: "status", title: "Статус", type: "select", sourceList: "statuses", sortable: true, width: 150 },
        { name: "value", title: "Сумма ", type: "number", sortable: true, width: 80 },
      ];
    },

    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
  },
  watch: {},
  methods: {
    onClick(e) {
      if (e.field?.isMenu) {
        if (["document"].includes(e.field.name)) {
          let menuItems = [
            {
              name: e.field.name,
              title: "Открыть документ",
              action: async () => {
                this.$refs.quickOpenDoc.openDocument(e.row.base_name, parseInt(e.row.doc_id), e.row);
              },
            },
          ];
          this.showPopupMenu(e.field.name, e.row, e.event, { menuItems });
        } else this.showPopupMenu(e.field.name, e.row, e.event, e);
        return;
      }
      this.idShow = e.row.id;
      this.$refs.quickOpenDoc.openDocument("AccountingDocActWorkClient", parseInt(e.row.id), e.row);
    },
    itemShowClose() {
      this.loading = true;
      let id = this.idShow;
      this.idShow = 0;
      if (id) this.$refs.widget.updateRowData(id);
      this.loading = !true;
    },

    async fitchData() {
      this.afterFitch();
    },
    afterFitch() {
      this.loading = false;
    },
  },
};
</script>
